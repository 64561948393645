import React from 'react'
import type { PageProps } from "gatsby"
import Helmet from 'react-helmet'
import StandardLayout from '../layouts/standard'
import ContactLinks from '../modules/contact'

const ContactPage = ({location}: PageProps) => (
	<StandardLayout location={location}>
		<Helmet>
			<body className="contact-page" />
		</Helmet>

		<ContactLinks />
	</StandardLayout>
)

export default ContactPage
