import React from 'react'
import {StaticQuery, graphql} from 'gatsby'
import List from '../components/list'

interface ContactLinksProps {
	data: Queries.ContactLinksQuery
}

const ContactLinks = ({data}: ContactLinksProps) => {
	const email = data?.site?.siteMetadata?.email ?? 'shelburnetim@gmail.com'
	const agencies = data?.agencies?.items?.filter(Boolean) as Contentful.Agency[] ?? []
	const links = data?.links?.items?.filter(Boolean) as Contentful.Link[] ?? []
	
	return <section>
		<h1 className="card-style-block-text">Representation</h1>
		<hr />

		<section className="representation">
			{groupByType(agencies).map(agencies => (
				<>
					{agencies[0].type.length === 1 && <h3>{agencies[0].type}</h3>}

					<List className="agencies">
						{agencies.map(agent => (
							<address key={agent.name} className="agency">
								<h4 className="agency-name">
									<a href={agent.website}>
										{agent.name}
										{agent.limitation && ` (${agent.limitation})`}
									</a>
								</h4>
								{agent.type.length > 1 && <h5>{agent.type.join(`, `)}</h5>}
								{agent.contact}
								<br />
								{agent.email && (
									<a href={`mailto:${agent.email}`}>{agent.email}</a>
								)}
								<br />
								{agent.phone && (
									<a href={`tel:${agent.phone}`}>{agent.phone}</a>
								)}
								<br />
								{agent.website && <a href={agent.website}>{agent.website}</a>}
								<br />
							</address>
						))}
					</List>
				</>
			))}

			<h2>Based in New York, NY</h2>
			<a href={`mailto:${email}`}>{email}</a>

			<h2>Links</h2>
			<List>
				{links.map(link => (
					<a href={link?.url} target="_blank" rel="noopener noreferrer">
						{link?.title}
					</a>
				))}
			</List>
		</section>
	</section>
}

export default (props: Omit<ContactLinksProps, 'data'>) => (
	<StaticQuery
		query={graphql`
			query ContactLinks {
				site {
					siteMetadata {
						email
					}
				}
				agencies: contentfulOrdering(name: {eq: "Contact Agencies"}) {
					items {
						... on ContentfulAgent {
							name
							type
							limitation
							contact
							email
							phone
							website
						}
					}
				}
				links: contentfulOrdering(name: {eq: "Contact Links"}) {
					items {
						... on ContentfulLink {
							title
							url
						}
					}
				}
			}
		`}
		render={data => <ContactLinks {...props} data={data} />}
	/>
)

function groupByType(agencies: readonly Contentful.Agency[]): Contentful.Agency[][] {
	type AgencyMap = Record<'Multiple' | Contentful.AgencyType, Contentful.Agency[]>

	const groupObj = agencies.reduce<AgencyMap>(
		(groups, agent) => {
			const type = agent.type.length === 1 ? agent.type[0] : `Multiple`

			return {
				...groups,
				[type]: [...groups[type], agent],
			}
		},
		{
			Multiple: [],
			'Film / TV': [],
			'Commercial / Print': [],
			Theater: [],
		}
	)

	return Object.values(groupObj).filter(group => group.length > 0)
}
